<!--
 * @Description: 公众号 绑定车牌列表 bindPlateNumber
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-22 14:59:37
 * @LastEditors: zhoucheng
-->
<!--  -->
<template>
  <div class='body'>
    <van-nav-bar title="绑定车辆列表" />
    <!-- 列表 -->
    <van-row class="main">
      <van-list class="tablePartBox">
        <van-cell v-for="item in list"
                  :key="item.key"
                  class="itemBox">
          <van-row>
            <van-row>
              <span class="plateNumber">
                {{item.plateNumber}}
                <van-tag class="tagClass">{{item.plateColor}}</van-tag>
              </span>
            </van-row>
            <van-row style="margin-top:5px">
              <span>
                {{item.bingTime}}
              </span>
              <span class="details"
                    @click="handleDelete(item)">
                取消绑定
              </span>
            </van-row>
          </van-row>
        </van-cell>
      </van-list>
    </van-row>
    <div class="but-box">
      <van-button :disabled="list.length>=3"
                  @click="handleAdd">{{list.length>=3?'最多绑定3个车牌':'绑定车牌'}}</van-button>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { Dialog, Toast } from 'vant'
import getUserInfo from '@/utils/getUserInfo.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      // 绑定车牌列表
      list: []
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    getUserInfo().then(() => {
      this.onLoad()
    })
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 渲染列表页面-查询车牌
    onLoad () {
      this.$bindPlateNumber.queryBindPlateNumber({}).then(response => {
        this.list = response.resultEntity
      })
    },
    // 解绑车牌
    handleDelete (e) {
      var query = e
      Dialog.confirm({
        title: '提示',
        message: '确认解绑该车牌吗？'
      }).then(() => {
        const info = {
          plateNumber: query.plateNumber
        }
        this.$bindPlateNumber.unBindPlateNumber(info).then(res => {
          if (res.resultCode === '2000') {
            Toast.success('解绑成功')
            this.onLoad()
          }
        })
      })
    },
    // 绑定车牌
    handleAdd () {
      this.$router.push('/plateBind')
    },
    // 顶部返回事件跳转
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    padding: 5px;
    .tablePartBox {
      margin: 12px 3.6%;
      .itemBox {
        margin-bottom: 10px;
        background-color: #fff;
        border-radius: 5px;
        .plateNumber {
          display: flex;

          font-size: 20px;
          color: #333333;
          font-weight: 500;
          .color {
            width: 30px;
            height: 23px;
            margin-left: 10px;
            text-align: center;
            color: white;
            background: #597fc5;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 500;
          }
        }
        .status {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 400;
          color: white;
          margin-top: 15px;
        }
      }
      .tagClass {
        width: 34px;
        height: 17px;
        background: #597fc5;
        border-radius: 5px;
        justify-content: space-around;
        margin-top: 3px;
        margin-left: 2%;
      }
      .findCar {
        margin-left: 12px;
        color: #333333;
        font-size: 15px;
        font-weight: 400;
      }
      .details {
        position: relative;
        float: right;
        color: #597fc5;
        font-weight: 400;
        font-size: 15px;
      }
    }
  }
}
// 在停与离开状态颜色变化
.onCar {
  color: red;
}
.onCar {
  color: blue;
}
.but-box {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 93.6%;
  margin-left: 3.2%;
  height: 80px;
  .van-button {
    width: 100%;
    color: #ffffff;
    font-size: 17px;
    border-radius: 5px;
    background: #19a9fc;
  }
}
.green,
.blue {
  width: 30px;
  height: 23px;
  margin-left: 10px;
  text-align: center;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}
.blue {
  background: #409eff;
}
.green {
  background: #67c23a;
}
.yello {
  background: #e6a23c;
}
.white {
  background: #fff;
}
.black {
  background: black;
}
.none {
  background: #909399;
}
</style>
